body {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    background-color: #f7f5f1 !important;
    color: #333;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  
  .changelog-container {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #fff; 
    border: 1px solid #fff;
  }
  
  .changelog-main {
    width: 100%;
    padding-top: 30px;
    overflow-y: auto;
    font-size: 14px;
  
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .changelog-main::-webkit-scrollbar {
    display: none;
  }
  
  .changelog-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    color: #192841;
  }
  
  .changelog-header .changelog-title {
    font-size: 30px;
    font-weight: 300;
    margin: 20px 0 25px 0;
    color: #192841;
  }

  .changelog-subtitle {
    font-size: 14px;
    font-weight: 700 !important;
    margin: 20px 0 25px 0;
    color: #333;
  }
  
  .changelog-description {
    color: #333;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 25px;
    width: calc(100% - 20px) !important;
  }

  .changelog-list {
    list-style-type: square;
    margin-left: 16px;  
    margin-bottom: 25px;
    padding-left: 0;
  }

  .changelog-list li {
    margin-bottom: 6px;
  }

  