body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.register-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden;
}

.register-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 300;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.register-main::-webkit-scrollbar {
  display: none;
}

.register-header {
  display: flex;
  margin-bottom: 35px;
  color: #192841;
  justify-content: space-between;
}

.register-title {
  font-size: 30px;
  margin: 20px 0 25px 0;
  color: #192841;
}

.register-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
}

.register-error {
  color: #eb652b;
  text-align: center;
  margin-bottom: 20px;
}

.register-form {
  width: 100%;
}

.register-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.register-form-group,
.register-form-group-full {
  width: 100%;
}

.register-form-group {
  flex: 1;
  box-sizing: border-box;
}

.register-form-group-full {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.register-label {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.register-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.register-input:focus {
  background-color: white !important; 
  outline: none; 
  border: 1px solid #eb652b;
}

.register-input:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.register-input::placeholder {
  color: #999;
}

.register-section-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.register-section-btn {
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  color: white;
  width: 180px;
  box-sizing: border-box;
}

.register-btn-submit {
  border: 1px solid #eb652b;
  background-color: #eb652b;
  color: #fff;
}

.register-btn-submit:hover {
  background-color: #192841;
  color: #fff;
  border: 1px solid #192841;
}

.register-btn-submit:active {
  background-color: #192841;
  color: white;
  transform: scale(0.97); 
  transition: transform 0.5s ease; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

@media (max-width: 1200px) {
  .register-input {
    margin-right: 0 !important;
  }

  .register-section-buttons {
    margin-bottom: 80px !important;
  }
}

@media (max-width: 768px) {
  .register-input {
    width: 100% !important;
  }

  .register-section-buttons {
    margin-bottom: 100px !important;
  }

  .register-section-btn {
    width: 100%;
  }

  .register-row {
    flex-direction: column;
    gap: 10px;
  }

  .register-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.radio-privacy-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: auto;
  padding: 0;
  margin: 0;
}

.radio-privacy {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100% !important;
}

.radio-privacy input[type="radio"] {
  border: 0.5px solid #192841; 
  background-color: transparent;
  appearance: none; 
  border-radius: 50%; 
  width: 15px !important; 
  height: 15px !important; 
  position: relative;
  cursor: pointer;
  box-sizing: border-box; 
  padding: 10px; 
  margin: 0 3px 0 0;
}

.radio-privacy input[type="radio"]:checked {
  border: 0.5px solid #192841; 
  background-color: transparent;
  margin-right: 5px !important;
}

.radio-privacy input[type="radio"]:checked::after {
  content: "";
  width: 8px !important; 
  height: 8px !important; 
  background-color: #192841; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.radio-privacy-label {
  font-weight: 300;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  margin: 0 0 0 5px; 
  padding: 0;
}


.register-success-message {
  margin-top: 20px;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
}
