.tooltip {
    position: absolute;
    background: #fff;
    color: #000;
    border: 0.5px solid #d3d3d3;
    padding: 10px;
    border-radius: 4px;
    white-space: pre-wrap;
    max-width: 500px;
    word-wrap: break-word;
    font-size: 12px;
  }
  
  