html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

#root {
  height: 100%;
}

.app-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 90px;
  justify-content: start;
  align-items: center;
  height: 100vh;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.sidebar {
  overflow: hidden;
  min-width: 200px;
  max-width: 250px;
  background-color: #eb652b;
  padding: 30px;
  border-right: 1px solid #eb652b;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
}

.sidebar,
.sidebar * {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.sidebar-header {
  margin: 20px 0 40px 0;
  text-align: left;
}

.sidebar-header h2 {
  font-size: 30px;
  font-weight: 300;
  color: #192841;
  margin: 0;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
}

.sidebar nav ul li {
  padding: 12px 0;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
}

.sidebar nav ul li ul {
  list-style-type: none;
  padding-left: 15px;
  margin-top: 10px;
}

.sidebar nav ul li ul li {
  font-weight: 300;
  padding: 8px 0;
  cursor: pointer;
}

.sidebar nav ul > li.active {
  color: #192841;
}

.sidebar nav ul > li > ul > li {
  color: #fff;
}

.sidebar nav ul > li > ul > li.active {
  font-weight: bold;
  color: #fff;
}

.sidebar nav ul li:hover {
  color: #192841;
}

.sidebar-bottom {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.sidebar-bottom ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-copyright a {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;
}


.login-btn,
.register-btn,
.logout-btn,
.profile-btn {
  background-color: #eb652b;
  color: white;
  padding: 10px 10px !important;
  border-radius: 4px;
  border: 1px solid #fff !important;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
  border: none;
  display: block;
  width: 80% !important;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.login-btn.active,
.register-btn.active {
  background-color: #192841;
  color: #fff !important;
  border: 1px solid #192841 !important;
}

.login-btn:hover,
.register-btn:hover,
.logout-btn:hover,
.profile-btn:hover {
  background-color: #192841;
  color: #fff !important;
  border: 1px solid #192841 !important;
}

.auth-container {
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.auth-container h2 {
  text-align: center;
}

.auth-container form {
  display: flex;
  flex-direction: column;
}

.auth-container input {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  font-weight: 300;
}

.auth-container button {
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  background-color: #eb652b;
  color: #fff;
  border: none;
  cursor: pointer;
}

.auth-container .error {
  color: #eb652b;
  text-align: center;
}

@media (max-width: 1200px) {
  .app-container {
    width: 100%;
  }
  .sidebar {
    padding: 20px;
  }
  .sidebar-header h2 {
    font-size: 24px;
  }
  .sidebar nav ul li {
    padding: 10px 0;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 0 34px;
  }
  .sidebar-bottom {
    position: relative;
    width: 79%;
    margin-top: 0;
  }
  .sidebar {
    position: absolute;
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    overflow: hidden;
    height: 0px;
    z-index: 3;
  }
  .sidebar.close {
    animation: closeMenu 0.1s ease-in-out forwards;
    overflow: hidden;
    height: 0 !important;
  }
  .sidebar.open {
    animation: openMenu 0.4s ease-in-out forwards;
    height: 100vh !important;
  }
  .sidebar-header {
    display: none;
  }
  .sidebar nav ul li {
    padding: 8px 0;
  }
  .sidebar nav ul {
    font-size: 16px;
    font-weight: 300;
    padding: 0 29px;
    margin-top: 60px;
  }
  .sidebar nav li {
    font-size: 15px !important;
    font-weight: 300;
    width: 80%;
  }
  .app-container {
    width: 100%;
  }
  .login-btn,
  .register-btn,
  .logout-btn,
  .profile-btn {
    margin-left: 30px;
  }
}

@keyframes closeMenu {
  from {
    height: 100vh;
    transition: all;
    opacity: 1;
  }
  to {
    height: 0px !important;
    transition: all;
    opacity: 0;
  }
}

@keyframes openMenu {
  from {
    height: 0px;
  }
  to {
    height: 100vh !important;
    transition: all;
  }
}