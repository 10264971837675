body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.reset-password-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden;
}

.reset-password-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 300;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.reset-password-header {
  display: flex;
  color: #192841;
  margin-bottom: 35px;
  justify-content: space-between;
}

.reset-password-title {
  font-size: 30px;
  color: #192841;
  margin: 20px 0 25px 0;
}

.reset-password-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 20px;
}

.reset-password-error-message {
  color: #eb652b;
  text-align: left; 
  margin-top: 20px; 
  margin-bottom: 0; 
}

.reset-password-form {
  width: 100%;
}

.reset-password-form-group,
.reset-password-form-group-full {
  width: 100%;
  margin-bottom: 15px;
}

.reset-password-input {
  width: 40%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  background-color: white !important; 
  color: #333; /* Text color */
}

.reset-password-input:focus {
  background-color: white !important; 
  outline: none; 
  border: 1px solid #eb652b;
}

.reset-password-input:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.reset-password-input::placeholder {
  color: #fff;
}

.reset-password-section-btn {
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  color: white;
  width: 180px;
}

.reset-password-section-btn {
  border: 1px solid #eb652b;
  background-color: #eb652b;
  color: #fff;
}

.reset-password-section-btn:hover {
  background-color: #192841;
  color: #fff;
  border: 1px solid #192841;
}

.reset-password-section-btn:active {
  background-color: #192841; 
  color: white;
  transform: scale(0.97); 
  transition: transform 0.5s ease; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

@media (max-width: 768px) {
  .reset-password-form {
    width: 100%;
  }

  .reset-password-input {
    width: 100%;
  }

  .reset-password-section-btn {
    width: 100%;
  }
}