body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden !important;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.alerts-container {
  display: flex;
  width: 100%;
  /*height: 100vh;*/
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden !important;
}

.alerts-main {
  width: 100%;
  padding-top: 30px;
  overflow-x: hidden !important;
  font-size: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.alerts-main::-webkit-scrollbar {
  display: none;
}

.alerts-header {
  display: flex;
  align-items: center;
  color: #192841;
  justify-content: space-between;
  margin-bottom: 35px;
}

.alerts-header .alerts-title {
  font-size: 30px;
  font-weight: 300;
  color: #192841;
  margin: 20px 0 25px 0; 
}

.alerts-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.alerts-description:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.alerts-actions {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.btn {
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease;
}

.btn-primary {
  border: 0.8px solid #192841;
  background-color: #192841;
  color: #fff;
  width: 160px !important;
  text-align: left;
}

.btn-secondary {
  border: 0.8px solid #eb652b;
  background-color: #fff;
  color: #333;
  width: 320px !important;
  text-align: left;
}

.btn-danger {
  border: 0.8px solid #eb652b;
  background-color: #eb652b;
  color: #fff;
  width: 160px !important;
  text-align: left;
}

.btn-savealert:active {
  background-color: #eb652b; 
  color: white;
  transform: scale(0.97); 
  transition: transform 0.5s ease; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.btn-savealert {
  padding: 10px 10px;
  border: 1px solid #eb652b;
  background-color: #eb652b;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  color: white;
  width: 180px;
}

.btn-savealert:hover {
  background-color: #192841;
  color: #fff;
  border: 1px solid #192841;
}

.btn-savealert:active {
  background-color: #192841; 
  color: white;
  transform: scale(0.97); 
  transition: transform 0.5s ease; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.alert-form {
  width: 100%;
  font-family: Roboto, sans-serif;
}

.form-group {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.alert-form input {
  width: calc(100% - 40px) !important;
  padding: 8px;
  margin-top: 5px;
  margin-right: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px; 
}

input::placeholder {
  color: #999;
}

.radio-group {
  display: flex;
  gap: 20px !important; 
  margin-top: 10px;
}

.radio-group input[type="radio"] {
  border: 0.5px solid #d3d3d3; 
  background-color: transparent;
  appearance: none; 
  border-radius: 50%; 
  width: 15px !important; 
  height: 15px !important; 
  position: relative;
  cursor: pointer;
  box-sizing: border-box; 
  padding: 0; 
  margin: 0;
}

.radio-group input[type="radio"]:checked {
  border: 1px solid #192841; 
  background-color: transparent;
  margin-right: 5px !important;
}

.radio-group input[type="radio"]:checked::after {
  content: "";
  width: 8px !important; 
  height: 8px !important; 
  background-color: #192841; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.radio-group label {
  font-weight: 300;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  margin-right: 5px !important;
}

.alert-form input,
.multi-select-dropdown {
  width: calc(100% - 40px) !important;
  height: 20px;
  padding: 8px;
  margin-top: 5px;
  margin-right: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px; 
}

.alert-form input::placeholder {
  color: #333;
}

.alert-form input:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.multi-select-dropdown {
  position: relative;
  cursor: pointer;
}

.multi-select-dropdown .dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multi-select-dropdown .selected-options {
  flex: 1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-select-dropdown .placeholder {
  color: #333;
}

.multi-select-dropdown .dropdown-arrow {
  margin-left: 10px;
}

.multi-select-dropdown .dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  overflow-y: auto;
  z-index: 1000;
}

.multi-select-dropdown .dropdown-list-item {
  padding: 10px;
  cursor: pointer;
}

.multi-select-dropdown .dropdown-list-item:hover {
  background-color: #f0f0f0;
}

.multi-select-dropdown .dropdown-list-item.selected {
  background-color: #fff;
  color: #333;
}

.multi-select-dropdown .dropdown-list-item span {
  margin-left: 5px;
}

.multi-select-dropdown .dropdown-search-input {
  width: calc(100% - 20px);
  padding: 8px !important;
  margin-bottom: 10px;
  border: 1px solid #fff; 
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
}

.multi-select-dropdown .dropdown-search-input:focus {
  outline: none;
  border-color: #fff; 
}

.selected-option {
  display: inline-flex;
  align-items: center;
}

.remove-option {
  margin-left: 5px;
  cursor: pointer;
  color: #333;
}

.remove-option:hover {
  opacity: 0.8;
}

.comma {
  margin-right: 8px; 
}

.multi-select-dropdown .dropdown-list-item {
  padding: 10px;
  cursor: pointer;
}

.multi-select-dropdown .dropdown-list-item.selected {
  background-color: #e0e0e0;
}

.dropdown-arrow {
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.error-message {
  color: #eb652b; 
  margin-top: 20px !important;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
}

.success-message {
  color: #333; 
  margin-top: 20px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .alerts-actions {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .alerts-actions {
    flex-direction: column;
    margin-right: 20px;
  }

  .btn.btn-primary {
    width: 100% !important;
  }
  .btn.btn-danger {
    width: 100% !important;
  }

  .btn.btn-secondary {
    width: 100% !important;
    height: 40px;
  }

  .alert-form {
    margin-bottom: 94px;
  }

  .btn-savealert {
    margin-top: 10px !important;
  }
}
