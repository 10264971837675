.burger-btn svg {
  stroke: #192841;
  transition: 0.15s;
  z-index: 50;
}

.burger-btn svg g:first-child {
  opacity: 1;
  transition: opacity 0s 0.15s;
}

.burger-btn svg g:first-child line {
  transition: transform 0.15s 0.15s;
  transform: translateY(0px);
}

.burger-btn svg g:last-child {
  opacity: 0;
  transition: opacity 0s 0.15s;
}

.burger-btn svg g:last-child line {
  transition: transform 0.15s;
  transform: rotate(0deg);
  transform-origin: center;
}

.burger-btn.-menu-open svg g:first-child {
  opacity: 0;
}

.burger-btn.-menu-open svg g:first-child line:first-child {
  transition: transform 0.15s;
  transform: translateY(7px);
}

.burger-btn.-menu-open svg g:first-child line:last-child {
  transition: transform 0.15s;
  transform: translateY(-7px);
}

.burger-btn.-menu-open svg g:last-child {
  opacity: 1;
}

.burger-btn.-menu-open svg g:last-child line:first-child {
  transition: transform 0.15s 0.15s;
  transform: rotate(45deg);
}

.burger-btn.-menu-open svg g:last-child line:last-child {
  transition: transform 0.15s 0.15s;
  transform: rotate(-45deg);
}

.burger-btn {
  display: none;
}

.mobile-menu-header {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-header {
    display: block;
    margin-left: 30px; 
    margin-top: 22px;
    margin-bottom: 0px;
    font-size: 32px;
    color: #192841;
    font-family: "Roboto Light", sans-serif;
    font-weight: 300;
    width: calc(100% - 60px);
    height: 10px; 
  }

  .burger-btn {
    display: flex;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .sidebar-copyright {
    font-size: 10px; 
    font-weight: 300;
    margin-top: 5px; 
    padding-left: 30px; 
  }
}
