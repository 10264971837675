body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1 !important;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.developerresources-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff; 
  border: 1px solid #fff;
}

.developerresources-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.developerresources-main::-webkit-scrollbar {
  display: none;
}

.developerresources-header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  color: #192841;
}

.developerresources-header .developerresources-title {
  font-size: 30px;
  font-weight: 300;
  margin: 20px 0 25px 0;
  color: #192841;
}

.developerresources-subtitle {
  font-size: 14px;
  font-weight: 700 !important;
  margin: 20px 0 25px 0;
  color: #333;
}

.developerresources-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.developerresources-list {
  list-style-type: square;
  margin-left: 16px;  
  margin-bottom: 25px;
  padding-left: 0;
}

.developerresources-list li {
  margin-bottom: 6px;
}

.api-request {
  width: 30%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  box-sizing: border-box; 
  background-color: white;
}

.api-request:focus {
  background-color: white !important; 
  outline: none; 
  border: 1px solid #eb652b;
}

.api-request:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.api-request::placeholder {
  color: #333;
}
