body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1 !important;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.editor-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff; 
  border: 1px solid #fff;
  overflow: hidden;
}

.editor-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 300;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.editor-main::-webkit-scrollbar {
  display: none;
}

.editor-header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  color: #192841;
}

.editor-header .editor-title {
  font-size: 30px;
  font-weight: 300;
  margin: 20px 0 25px 0;
  color: #192841;
}

.editor-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.editor-columns {
  display: flex;
  width: 100%;
  margin-top: 20px; 
  align-items: flex-start; 
}

.editor-left-column {
  width: 75%; 
  padding-right: 20px; 
}

.editor-right-column {
  width: 25%; 
  margin-top: 20px; 
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.editor-output-container,
.editor-input-container {
  width: 100%;
  margin-top: 20px !important; 
}

.editor-output-container .w-md-editor {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.editor-output-container .w-md-editor-bar {
  background-color: #f7f5f1;
}

.editor-output-container .w-md-editor-text {
  font-size: 14px;
}

.editor-output-container .w-md-editor-content {
  height: 700px !important;
  overflow-y: auto;
}

.editor-output-field {
  width: 100%;
  height: 740px; 
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
  font-weight: 300;
  border: 1px solid #d3d3d3; 
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  resize: none; 
  outline: none; 
  display: block; 
  margin: 0; 
  overflow-y: auto;
}

.editor-output-field:focus {
  border-color: #a9a9a9; 
  border: 2px solid #eb652b;
}

.input-with-button {
  position: relative;
  width: 100%;
  height: 53px;
}

.editor-input-field {
  width: 100%;
  padding: 10px;
  height: 100%;
  padding-right: 50px; 
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #eb652b;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  transition: border-width 0.2s ease;
  resize: none;
}


.editor-output-container .ProseMirror {
  border: none;
  height: 100%;
  min-height: 100%;
}

.editor-output-container .ProseMirror:focus {
  outline: none;
}

.send-button {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%); 
  width: 25px; 
  height: 25px; 
  background-color: #eb652b;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  padding: 5px; 
}

.send-icon {
  width: 15px; 
  height: 15px;
  fill: #fff; 
}

.clipboard-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  padding-right: 3px;
  min-height: 20px;
  max-height: 480px; 
}

.clipboard-items::-webkit-scrollbar {
  width: 8px; 
}

.clipboard-items::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.clipboard-items::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px; 
  border: 2px solid #f1f1f1; 
}

.clipboard-items::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.clipboard-items {
  scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1; 
}

.clipboard-item {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-right: 5px;
}

.clipboard-item p {
  margin: 0;
  flex: 1 1 0; 
  min-width: 0; 
  overflow: hidden; 
  cursor: pointer;
}

.clipboard-item p:hover {
  text-decoration: none;
  color: #333;
}

.clipboard-item button + button {
  margin-left: 2px; 
}

.clipboard-item-content {
  display: flex;
  flex-direction: column;
}

.clipboard-item-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto, sans-serif;
}

.clipboard-item-date {
  display: block;
  font-size: 10px;
  color: #666; 
  margin-bottom: 4px; 
}

.clear-clipboard-btn {
  margin-top: 20px;
  background-color: #fff;
  color: #333;
  border: 1px solid #eb652b;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  width: 100%; /* Full width */
}

.clear-clipboard-btn:hover {
  background-color: #eb652b;
  color: #fff;
}

.export-btn {
  margin-top: 10px;
  background-color: #eb652b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  width: 100%; 
}

.export-btn:hover {
  background-color: #eb652b; 
}

.error-message {
  color: #eb652b;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

.clipboard-item p[data-tooltip] {
  position: relative;
}

.clipboard-item p[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%; 
  left: 100%; 
  transform: translateY(-50%) translateX(10px); 
  white-space: pre-wrap;
  background: #fff; 
  color: #333; 
  border: 0.5px solid #eb652b;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  width: 300px; 
  margin-left: 10px; 
}

.clipboard-item p[data-tooltip]:hover::before {
  content: "";
  position: absolute;
  top: 50%; 
  left: 100%; 
  transform: translateY(-50%); 
  border-width: 5px;
  border-style: solid;
  border-color: transparent #192841 transparent transparent; 
  margin-left: -1px; 
}

.search-container {
  width: 100% !important;
  margin-top: 0px;
  margin-bottom: 10px;
}

.search-input {
  width: 100% !important;
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #eb652b; 
  outline: none; 
  transition: border-color 0.1s ease; 
}

.editor-right-column {
  width: 25%;
  margin-top: 0; 
  overflow-y: auto;
  overflow-x: hidden; 
  display: flex;
  flex-direction: column;
}

.no-items-message {
  font-size: 12px;
  font-weight: 300;
  color: #333;
}

.char-limit-error {
  color: #333;
  font-size: 12px;
  font-weight: 300;
  margin-top: 5px;
}

.clear-editor-btn {
  margin-top: 10px; 
  background-color: #fff;
  color: #333;
  border: 1px solid #eb652b;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  width: 100%;
}

.clear-editor-btn:hover {
  background-color: #eb652b; 
  color: #fff;
}

.icon-button {
  background: transparent;
  border: none;
  padding: 6px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  border-radius: 4px; 
}

.icon-button:hover {
  background-color: transparent;
}

.copy-icon,
.trash-icon {
  width: 16px; 
  height: 16px;
  fill: #192841;
  transition: fill 0.1s ease;
}

.icon-button:hover .copy-icon,
.icon-button:hover .trash-icon {
  fill: #eb652b; 
}

.editor-output-container {
  width: 100%;
  min-height: 740px; 
  padding: 10px 10px 10px 10px; 
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background-color: #ffffff;
  overflow-y: auto;
  margin: 0;
  box-sizing: border-box;
  resize: none; 
  outline: none; 
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block; 
}

.editor-output-container:focus {
  border-color: #d3d3d3;
  border: 2px solid #eb652b;
}

.editor-output-container h1,
.editor-output-container h2,
.editor-output-container h3 {
  color: #192841;
}

.editor-output-container p {
  line-height: 1.2;
  margin-bottom: 10px;
  padding-left: 5px;
}

.editor-output-container ul,
.editor-output-container ol {
  padding-left: 20px;
  margin: 0;
  list-style-type: disc; 
}

.editor-output-container li {
  margin-left: 0;
  padding-left: 0;
  list-style-position: outside;
  display: list-item;
  line-height: 1.4;
  margin-bottom: 5px; 
}

.editor-output-container a {
  color: #eb652b;
  text-decoration: underline;
}

.editor-output-container code {
  background-color: #f3f3f3;
  padding: 2px 4px;
  border-radius: 4px;
}

.mobile-btns {
  display: none;
}

@media (max-width: 868px) {
  .editor-left-column {
    margin-top: 10px;
    width: 100%;
    height: 50% !important;
    padding: 0;
  }

  .editor-columns {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .editor-right-column {
    width: 100%;
  }

  .clipboard-items {
    max-height: 260px;
  }

  .search-container {
    margin-bottom: 0;
  }

  .clear-clipboard-btn,
  .clear-editor-btn,
  .export-btn {
    display: none;
  }

  .editor-output-container {
    width: 100%;
    min-height: 300px; 
  }

  .mobile-btns {
    display: block;
  }

  .last-mob-btn {
    margin-bottom: 94px;
  }
}
