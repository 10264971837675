body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1 !important;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.chat-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff; 
  border: 1px solid #fff;
}

.chat-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.chat-main::-webkit-scrollbar {
  display: none;
}

.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  color: #192841;
}

.chat-header .chat-title {
  font-size: 30px;
  font-weight: 300;
  margin: 20px 0 25px 0;
  color: #192841;
}

.chat-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.chat-columns {
  display: flex;
  width: 100%;
  margin-top: 20px; 
}

.chat-left-column {
  width: 75%; 
  padding-right: 20px; 
}

.chat-right-column {
  width: 25%; 
  margin-top: 20px; 
  display: flex;
  flex-direction: column;
}

.chat-output-container,
.chat-input-container {
  width: 100%;
  margin-top: 20px; 
}

.chat-output-field {
  width: 100%;
  height: 700px; 
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #d3d3d3; 
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  resize: none; 
  outline: none; 
  display: block; 
  margin: 0; 
  overflow-y: auto;
}

.chat-output-field:focus {
  border-color: #a9a9a9; 
  border: 2px solid #eb652b;
}

.input-with-button {
  position: relative;
  width: 100%;
  z-index: 0;
}

select {
  color: #333;
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.chat-input-field {
  position: relative;
  width: 100%;
  padding: 10px;
  min-height: 30px !important;
  max-height: 50px;
  padding-right: 50px; 
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #eb652b;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  transition: border-width 0.2s ease;
  resize: none;
}

.chat-forms .send-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); 
  width: 25px; 
  height: 25px; 
  background-color: #eb652b;
  border-radius: 24px;
  border: none;
  cursor: pointer;
}

.send-icon {
  width: 15px; 
  height: 15px;
  fill: #fff; 
}

.response-options-dropdown {
  position: relative;
  width: 100% !important;
  height: 35px !important;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: calc(25% - 10px); 
  height: 32px; 
}

.action-button {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 12px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  text-align: left;
  color: #fff;
  background-color: #eb652b;
  border: 1px solid #eb652b;
  border-radius: 4px;
  cursor: pointer;
}

.message {
  margin-bottom: 5px;
}

.message .message-role {
  font-weight: bold;
  margin-right: 5px;
  display: block; 
}

.message.user .message-role {
  color: #192841;
}

.message.assistant .message-role {
  color: #eb652b;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.message-content {
  display: block;
  margin-bottom: 0px; 
  margin-top: 5px; 
  padding-left: 0px; 
  line-height: 1.2; 
}

.message-content ul {
  padding-left: 20px; 
  margin: 0; 
}

.message-content li {
  margin-left: 0;
  padding-left: 0;
  list-style-position: outside; 
  display: list-item; 
  line-height: 1.4; 
}

#chat-form {
  margin-bottom: 20px;
}

.action-button-mobile {
  display: none;
}

@media (max-width: 868px) {
  .chat-columns {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .chat-output-field {
    height: 300px; 
  }

  .response-options-dropdown,
  .chat-right-column,
  .chat-left-column {
    width: 100%;
  }

  .chat-output-field,
  .chat-left-column {
    padding: 0 !important;
  }

  .chat-left-column {
    margin-bottom: 94px;
  }

  .action-button {
    display: none;
  }

  .action-button-mobile {
    display: flex;
  }
}
