html, body {
  font-family: Roboto, sans-serif !important;
  font-weight: 300;
  background-color: #f7f5f1 !important;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.overview-container {
  display: flex;
  background-color: #fff;
  border: 1px solid #fff;
  overflow: hidden;
  width: 100%;
}

.overview-main {
  width: 100%;
  margin-top: 30px;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  -ms-overflow-style: none;  
  scrollbar-width: none;     
}

.overview-main::-webkit-scrollbar {
  display: none;
}

.overview-section {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.overview-header {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 35px;
  color: #192841;
}

.overview-header .overview-title {
  font-size: 30px;
  margin: 20px 0 25px 0;
  color: #192841;
  text-align: center;
}

.overview-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: start;
}

.overview-paragraph {
  color: #333;
  line-height: 1.6;
  margin-top: 40px;
  text-align: left;
}

.box-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .box-container {
    grid-template-columns: 1fr;
  }
}

.box-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

.hover-card {
  position: relative;
  width: 100%;
  height: 220px;
  background-color: #f2efea; 
  color: #333;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 300;
  text-align: left;
  border: none;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; 
}

.hover-card-front {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; 
}

.hover-card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 18px;    
  line-height: 1.6;
  font-weight: 300;
  color: #fff;
  transform: translateY(100%);
  transition: transform 0.5s ease;
  z-index: 1;
}

.hover-card:hover {
  background-color: #eb652b;
  color: #fff;
}

.hover-card:hover .hover-card-front {
  opacity: 0;
}

.hover-card:hover .hover-card-back {
  transform: translateY(0);
}

.hover-card-front,
.hover-card-back {
  padding: 20px; 
  box-sizing: border-box; 
}

.hover-card-front-title {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .overview-section {
    width: 100%;
    padding: 0 10px;
  }

  .overview-header {
    margin-bottom: 0px;
  }

  .overview-title {
    font-size: 20px !important;
    margin-top: 40px !important;
  }

  .overview-description {
    margin: 0;
    margin-bottom: 14px;
  }

  .hover-card {
    height: 180px; 
    font-size: 18px;
  }

  .hover-card-back {
    font-size: 16px; 
  }

  .overview-paragraph {
    padding-bottom: 80px;
  }
}