body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.newsfeed-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  border: 1px solid #fff;
  overflow: hidden;
}

.newsfeed-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: auto;
  font-size: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.newsfeed-main::-webkit-scrollbar {
  display: none;
}

.newsfeed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  color: #192841;
}

.newsfeed-header .newsfeed-title {
  font-size: 30px;
  font-weight: 300;
  margin: 20px 0 25px 0;
  color: #192841;
}

.newsfeed-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.newsfeed-actions {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.news-summary-section {
  width: 100%;
  margin-top: 20px;
  padding-left: 0;
}

.news-summary-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  font-size: 12px;
}

.news-summary-table thead tr {
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  background-color: #fff;
}

.news-summary-table th,
.news-summary-table td {
  padding: 10px 20px 10px 0;
  text-align: left;
  vertical-align: top;
}

.news-summary-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.news-summary-table th:nth-child(1),
.news-summary-table td:nth-child(1) {
  width: 10%; 
}

.news-summary-table th:nth-child(2),
.news-summary-table td:nth-child(2) {
  width: 10%; 
}

.news-summary-table th:nth-child(3),
.news-summary-table td:nth-child(3) {
  width: 65%; 
}

.news-summary-table th:nth-child(4),
.news-summary-table td:nth-child(4) {
  text-align: right; 
  padding-right: 0;
}

.column-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.column-date,
.news-summary-date {
  padding-right: 0;
  padding-left: 0;
}

.column-summary {
  padding-left: 0;
  margin-left: 0;
}


.news-summary-content p {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.news-summary-title,
.news-summary-description {
  margin-left: 0;
  padding-left: 0;
}

.news-summary-title {
  margin: 0 0 10px 0;
}

.news-summary-description {
  margin: 0 0 10px 0;
  color: #333;
  line-height: 1.6;
}

.news-summary-heading {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.news-summary-heading a {
  color: #333;
  font-weight: bold;
  text-decoration: none;
}

.news-summary-heading a:hover {
  text-decoration: none;
  color: #eb652b;
}

.news-summary-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.news-summary-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: #333;
}

.news-summary-actions button:hover {
  color: #eb652b;
}

.news-summary-item.read {
  color: #999;
}

.news-summary-item.read .news-summary-date,
.news-summary-item.read .news-summary-title,
.news-summary-item.read .news-summary-heading,
.news-summary-item.read .news-summary-description {
  color: #999 !important;
}

.news-summary-item.read .news-summary-heading a {
  color: #999 !important;
}

.fa {
  font-family: FontAwesome;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px !important;
  height: 16px;
  border: 1px solid #333;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
  margin: 0;
  margin-top: -3px !important;
}

input[type="checkbox"]:checked {
  background-color: #fff;
  border-color: #eb652b;
}

input[type="checkbox"]:checked::after {
  content: "✓";
  color: #eb652b;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination-controls button {
  background-color: #eb652b;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 0 10px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  width: 80px;
}

.pagination-controls button[disabled] {
  background-color: #fff;
  border: 1px solid #eb652b;
  color: #333;
  cursor: not-allowed;
}

.pagination-controls .page-number {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  color: #333;
  margin: 0 5px;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: none;
  background: none;
}

.pagination-controls .page-number.active {
  font-weight: bold;
  text-decoration: underline;
}

.pagination-controls .page-number:hover {
  color: #eb652b;
}

.clear-filters {
  background-color: #eb652b;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  border-radius: 4px;
}

.clear-filters:hover {
  background-color: #d15421;
}

.filters-actions-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.left-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex-grow: 1;
}

.export-options-dropdown {
  position: relative;
  padding: 8px 12px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid #eb652b;
  border-radius: 4px;
  width: calc(25% - 5px);
  height: 32px;
  min-width: 150px;
  appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23192841" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 5.658A.5.5 0 0 1 2.825 5h10.349a.5.5 0 0 1 .374.658l-4.796 5.482a.5.5 0 0 1-.754 0z"/></svg>')
    no-repeat right 10px center;
  background-color: white;
  background-size: 12px;
  color: #333;
}

.export-options-dropdown:focus {
  outline: none;
}

.dropdown-toggle {
  background-color: #fff;
  color: #333;
  border: 1px solid #192841;
  padding: 8px 16px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 10px;
  margin: 5px 0 0 0;
  min-width: 150px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.export-options-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 5px 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.dropdown-menu input {
  margin-right: 10px;
}

.export-button {
  background-color: #eb652b;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  border-radius: 4px;
}

.export-button:hover {
  background-color: #d15421;
}

.refresh-button {
  background-color: #192841;
  color: white;
  border: none;
  padding: 10px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.custom-divider {
  border: none !important;
  height: 1px !important;
  background-color: #d3d3d3 !important;
  margin: 10px 0;
  width: 100%;
}

.filters-section {
  margin-bottom: 60px;
}

.filters-section h3 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}

.filters-section hr {
  border: none;
  border-top: 1px solid #192841;
  margin-bottom: 20px;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.filters-subsection-container {
  width: 100%;
  margin-bottom: 15px;
}

.filters-subsection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.filters-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.filters-row.long-input {
  display: flex;
  gap: 20px;
  color: #333;
  height: 35px !important;
  width: 100% !important;
}

.filters-row.long-input .search-input::placeholder {
  color: #333;
}

.filters-row.long-input .search-input:-ms-input-placeholder {
  color: #333;
}

.filters-row.long-input .search-input::-ms-input-placeholder {
  color: #333;
}

.filters-row.long-input .search-input::-webkit-input-placeholder {
  color: #333;
}

.news-summary-actions {
  display: flex;
  gap: 5px;
  justify-content: end;
  align-items: center;
}

.icon-button-newsfeed {
  background: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}

.advanced-link {
  text-align: end !important;
}

.icon-button-newsfeed:hover {
  background-color: transparent;
}

.action-icon {
  width: 18px;
  height: 18px;
  fill: #192841;
  transition: fill 0.1s ease;
}

.action-icon2 {
  width: 16px;
  height: 16px;
  fill: #192841;
  transition: fill 0.1s ease;
}

.icon-button-newsfeed:hover .action-icon,
.icon-button-newsfeed:hover .action-icon2 {
  fill: #eb652b;
}

.icon-read {
  fill: #eb652b !important;
}

.icon-flagged {
  fill: #eb652b !important;
}

.news-sum-sec-mobile {
  display: none;
}

@media (max-width: 1500px) {
  .filters-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .column-date {
    flex: 2;
  }
}

.hide-desktop {
  display: none;
}

.css-19bb58m {
  margin: 0 !important;
  position: relative !important;
}

.css-19bb58m input#react-select-3-input {
  min-width: 100px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.custom-drop {
  padding: 0 !important;
  border: none;
  width: 100%;
  margin: 0;
}

.filter-group {
  margin-bottom: 16px;
}

@media (max-width: 990px) {
  .filters-row {
    grid-template-columns: 1fr;
  }
  .pagination-controls {
    margin-bottom: 94px !important;
  }
  .filters-actions-container {
    flex-direction: column;
    gap: 20px;
  }
  .export-options-dropdown {
    width: 100% !important;
  }
  .export-button {
    width: 100%;
  }
  .clear-filters {
    width: 100%;
  }
  .filter-coll-btn {
    font-size: 12px !important;
    font-weight: 300;
  }
  .right-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .news-sum-sec-mobile {
    display: grid;
  }
  .news-summary-item {
    flex-direction: column;
  }
  .mobile-filter-btns-news {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
  .column-actions {
    flex: 0;
  }
  .column-date.news-summary-date {
    display: block !important;
  }
  .column-checkbox.news-summary-checkbox {
    gap: 10px;
  }
  .news-summary-title {
    margin: 0;
  }
  .news-summary-item {
    padding: 0;
    margin-top: 19px;
  }
  .filters-section {
    margin-bottom: 4px;
  }
  .mobile-filter-btns-news-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3px;
  }
  .column-date.news-summary-date {
    display: flex;
    gap: 4px;
    padding-block-start: 1px;
  }
  .column-checkbox.news-summary-checkbox {
    margin-block-end: 1px;
  }
  .checkbox-mob-feed {
    margin-right: 8px !important;
  }
  .cover-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
  .hide-mobile {
    display: none;
  }
  .filters-section h2.hide-mobile {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
    color: #333 !important;
    line-height: normal !important;
  }
  h2.hide-mobile {
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-left: 0;
    margin-bottom: 20px;
    color: #333;
  }
  .hide-desktop {
    display: block !important;
    width: 34px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
  }
  .filter-collapse {
    cursor: pointer;
    width: 100% !important;
  }
  .force-collapse {
    display: none;
  }
  .filter-coll-btn {
    border: 1px rgba(0, 0, 0, 0.274) solid;
    border-radius: 4px;
    padding: 11px 10px;
  }
  .abs-arrow-btn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    width: 22px;
    height: 22px;
    margin-right: 2px;
  }
  .pos-relative {
    position: relative;
  }
  .rotate-all {
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s ease;
  }
  .icon-button-newsfeed {
    padding: 3.5px;
  }
  .mobile-filter-btns-news {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  width: 1000px;
  height: 1000px;
  max-width: 90%;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  border-radius: 8px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: #192841 !important;
}

.modal-header-actions {
  display: flex;
  align-items: center;
  font-weight: 300 !important;
}

.toggle-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #eb652b;
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  width: 80px;
  height: 30px;
}

.toggle-button.active {
  background-color: #eb652b;
  color: #fff;
}

.toggle-button:hover {
  background-color: #eb652b;
  color: #fff;
}

.close-modal-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 20px;
  padding-right: 2px;
}

.modal-body {
  margin-top: 20px;
}

.modal-news-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.modal-news-item-content {
  flex: 1;
}

.modal-news-item-actions {
  margin-left: 10px;
  margin-top: 10px;
}

.modal-news-item-actions .icon-button-newsfeed {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal-news-item-actions .action-icon2 {
  width: 16px;
  height: 16px;
  fill: #192841;
  transition: fill 0.1s ease;
}

.modal-news-item-actions .icon-button-newsfeed:hover .action-icon2 {
  fill: #eb652b;
}

.modal-news-item h4 {
  margin: 0 0 5px 0;
}

.modal-news-date {
  font-weight: normal;
  margin-bottom: 5px;
}

.modal-news-authority {
  margin-bottom: 5px;
}

.modal-news-title a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding-top: 5px !important;
}

.modal-news-title a:hover {
  text-decoration: none;
  color: #eb652b;
}

.modal-news-summary {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .modal-content {
    width: 90%;
    height: 90%;
    padding: 20px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
    padding: 30px;
    padding-top: 100px;
    border-radius: 0;
  }
  .modal-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .modal-header h3 {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 20px;
    color: #192841 !important;
  }
  .modal-header-actions {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }
  .toggle-button {
    width: 40%;
    padding: 0px;
    margin-left: 0;
  }
  .close-modal-button {
    margin-left: 0;
    margin-right: 2px;
    align-self: flex-end;
    color: #333;
  }
  .close-modal-button {
    top: 10px;
    right: 10px;
    font-size: 20px;
    margin-left: 0;
    align-self: flex-end;
    color: #333;
  }
  .modal-news-item {
    display: grid;
    grid-template-areas:
      "date clipboard"
      "authority clipboard"
      "title clipboard"
      "summary clipboard";
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    position: relative;
    padding-right: 10px;
  }
  .modal-news-item-content .modal-news-date {
    grid-area: date;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .modal-news-item-actions {
    grid-area: clipboard;
    justify-self: end;
    align-self: start;
    margin-top: -2px;
    margin-right: -5px;
  }
  .news-sum-sec-desktop {
    display: none;
  }
  .news-sum-sec-mobile {
    display: grid;
    font-size: 12px;
  }
  .news-summary-header {
    display: none;
  }
}