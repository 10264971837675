body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.apikey-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden;
}

.apikey-main {
  width: 100%;
  padding-top: 30px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  font-size: 14px;
  font-weight: 300;
  font-weight: 300;
}

.apikey-header {
  display: flex;
  margin-bottom: 35px;
  color: #192841;
  justify-content: space-between;
}

.apikey-title {
  font-size: 30px;
  font-weight: 300;
  color: #192841;
  margin: 20px 0 25px 0;
}

.apikey-description {
  color: #333;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.apikey-description:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.apikey-request-error,
.apikey-request-message {
  margin-top: 30px; 
  text-align: left; 
  font-size: 14px; 
  font-weight: 300;
}

.apikey-request-error {
  color: #eb652b;
}

.apikey-request-message {
  color: #333;
}

.apikey-request-form {
  width: 100%;
}

.apikey-request-row {
  display: flex;
  justify-content: space-between;
  gap: 0px;
}

.apikey-request-form-group,
.apikey-request-form-group-full {
  width: 100%;
}

.apikey-request-form-group {
  flex: 1;
  box-sizing: border-box; 
}

.apikey-request-form-group-full {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.apikey-request-label {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.apikey-request-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  box-sizing: border-box; 
  background-color: white;
}

.apikey-request-input:focus {
  background-color: white !important; 
  outline: none; 
  border: 1px solid #eb652b;
}

.apikey-request-input:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.apikey-request-input::placeholder {
  color: #333;
}

.apikey-request-purpose {
  min-height: 150px;
  padding-left: 12px !important;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: #333;
  resize: none;
}

.apikey-buttons {
  display: flex;
  justify-content: flex-start; 
  gap: 20px !important; 
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; 
  max-width: 800px; 
}

.apikey-request-btn {
  padding: 10px 10px;
  background-color: #fff;
  color: #333;
  border: 1px solid #eb652b;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  width: 180px; 
  box-sizing: border-box; 
  margin-right: 20px; 
}

.apikey-request-btn:hover {
  background-color: #eb652b;
  color: white;
  transform: scale(0.97) !important; 
  transition: transform 0.5s ease; 
}

.apikey-request-btn:active {
  background-color: #eb652b;
  color: white;
  transform: scale(0.97) !important; 
  transition: transform 0.5s ease; 
}

.apikey-manage-section {
  margin-top: 40px; 
}

.apikey-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.apikey-table thead tr {
  border-bottom: 1px solid #ccc;
}

.apikey-table tbody tr {
  border-bottom: 1px solid #ccc;
}

.apikey-table th, .apikey-table td {
  text-align: left;
  padding: 10px 0;
}

.apikey-table th:nth-child(1),
.apikey-table td:nth-child(1) {
  width: 20%;
}

.apikey-table th:nth-child(2),
.apikey-table td:nth-child(2) {
  width: 50%;
}

.apikey-table th:nth-child(3),
.apikey-table td:nth-child(3) {
  width: 15%;
}

.apikey-table th:nth-child(4),
.apikey-table td:nth-child(4) {
  width: 15%;
}

.apikey-key-cell {
  display: flex;
  align-items: center;
}

.apikey-displayed-key {
  flex: 0 0 auto;
  font-weight: 300;
}

.apikey-key-buttons {
  margin-left: auto;
  display: flex;
  gap: 10px;
  align-items: end;
}

.apikey-toggle-btn,
.apikey-copy-btn {
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  padding: 0 5px; 
  transition: color 0.3s ease, transform 0.2s ease;
}

.apikey-toggle-btn:active,
.apikey-copy-btn:active {
  transform: scale(0.97);
}



@media (max-width: 768px) {
  .apikey-main {
    overflow-y: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .apikey-main::-webkit-scrollbar {
    display: none;
  }
  
  .apikey-request-input {
    width: 100% !important;
  }

  .apikey-request-row {
    gap: 6px;
    flex-direction: column;
  }

  .apikey-request-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px !important;
  }

  .profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


}
