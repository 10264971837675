body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.profile-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden;
}

.profile-main {
  width: 100%;
  max-width: 800px;
  padding-top: 30px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-weight: 300;
}

.profile-header {
  display: flex;
  margin-bottom: 35px;
  color: #192841;
  justify-content: space-between;
}

.profile-title {
  font-size: 30px;
  font-weight: 300;
  color: #192841;
  margin: 20px 0 25px 0;
}

.profile-message,
.profile-error {
  margin-top: 30px; 
  text-align: left; 
  font-family: Roboto, sans-serif;
  font-size: 14px; 
  font-weight: 300;
}

.profile-error {
  color: #eb652b;
}

.profile-message {
  color: #333;
}

.profile-form {
  width: 100%;
}

.profile-row {
  display: flex;
  justify-content: space-between;
  gap: 0px;
}

.profile-form-group,
.profile-form-group-full {
  width: 100%;
}

.profile-form-group {
  flex: 1;
  box-sizing: border-box; 
}

.profile-form-group-full {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.profile-label {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.profile-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-weight: 300;
  margin-bottom: 10px;
  box-sizing: border-box; 
}

.profile-input:focus {
  background-color: white !important; 
  outline: none; 
  border: 1px solid #eb652b;
}

.profile-input:-webkit-autofill {
  background-color: white !important; 
  color: #333 !important; 
  transition: background-color 5000s ease-in-out 0s; 
}

.profile-input::placeholder {
  color: #999;
}

.profile--section-buttons {
  display: flex;
  justify-content: flex-start; 
  gap: 20px !important; 
  margin-top: 20px;
  width: 100%; 
  max-width: 800px; 
}

.profile-section-btn {
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  transition: background-color 0.3s ease, transform 0.2s ease; 
  color: white;
  width: 180px; 
  box-sizing: border-box; 
  margin-right: 20px; 
}

.profile-section-btn-save {
  background-color: #eb652b;
}

.profile-section-btn-save:active {
  color: white;
  transform: scale(0.97) !important; 
  transition: transform 0.5s ease; 
}

.profile-section-btn-delete {
  background-color: white;
  border: 1px solid #eb652b;
  color: #192841;
}

.profile-section-btn-delete:hover {
  background-color: white;
  color: #192841;
  border: 1px solid #192841;
}

.profile-section-btn-delete:active {
  background-color: white;
  color: #192841;
  transform: scale(0.97) !important; 
  transition: transform 0.5s ease; 
}

@media (max-width: 768px) {
  .profile-input {
    width: 100% !important;
  }

  .profile-row {
    gap: 6px;
    flex-direction: column;
  }

  .profile-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px !important;
  }

  .profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .profile-main {
    overflow-y: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .profile-main::-webkit-scrollbar {
    display: none;
  }
}
