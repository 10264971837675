body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: #f7f5f1;
  color: #000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden !important;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.enforcement-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: white;
  border: 1px solid #fff;
  overflow: hidden !important;
}

.enforcement-main {
  width: 100%;
  padding-top: 30px;
  overflow-x: hidden !important;
  font-size: 14px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.enforcement::-webkit-scrollbar {
  display: none;
}

.enforcement-header {
  display: flex;
  align-items: center;
  color: #192841;
  justify-content: space-between;
  margin-bottom: 35px;
}

.enforcement-header .enforcement-title {
  font-size: 30px;
  font-weight: 300;
  color: #192841;
  margin: 20px 0 25px 0;
}

.enforcement-description {
  color: #000;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 25px;
  width: calc(100% - 20px) !important;
}

.enforcement-description:-webkit-autofill {
  background-color: white !important;
  color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.enforcement-actions {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.divider-line {
  margin: 20px 0;
  width: 100%;
  border: 0;
  height: 1px;
  background-color: #ccc;
}

.enforcement-subtitle-regular {
  font-size: 14px;
  font-weight: 700 !important;
  margin-left: 0px;
  margin-bottom: 20px;
  color: #000;
}

.enforcement-subtitle.active-tab {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.enforcement-subtitle {
  font-size: 14px;
  font-weight: 300;
  color: #000;
  cursor: pointer; 
}

.enforcement-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 300 !important;
}

.enforcement-table th:nth-child(1),
.enforcement-table td:nth-child(1) {
  width: 6%;
}

.enforcement-table th:nth-child(2),
.enforcement-table td:nth-child(2) {
  width: 10%;
}

.enforcement-table th:nth-child(3),
.enforcement-table td:nth-child(3) {
  width: 50%;
}

.enforcement-table th:nth-child(4),
.enforcement-table td:nth-child(4) {
  width: 13%;
}

.enforcement-table th:nth-child(5),
.enforcement-table td:nth-child(5) {
  width: 13%;
}

.enforcement-table th:nth-child(6),
.enforcement-table td:nth-child(6) {
  width: 8%;
}

.enforcement-table th,
.enforcement-table td {
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  text-align: left;
  text-align: left;
  vertical-align: top;
}

.enforcement-table thead th {
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.enforcement-table thead tr {
  border-bottom: 1px solid #ccc;
}

.enforcement-table tbody tr {
  border-bottom: 1px solid #ccc;
}

.enforcement-table th:nth-child(3),
.enforcement-table td:nth-child(3) {
  padding-right: 40px;
}

.enforcement-filters-row {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.enforcement-filter-dropdown {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  background-color: #fff;
}

.enforcement-filter-dropdown:focus {
  background-color: white !important;
  outline: none;
  border: 1px solid #eb652b;
}

.enforcement-filter-dropdown:-webkit-autofill {
  background-color: white !important;
  color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.enforcement-filter-dropdown::placeholder {
  color: #000;
}

.enforcement-search {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  background-color: #fff;
}

.enforcement-search:focus {
  background-color: white !important;
  outline: none;
  border: 1px solid #eb652b;
}

.enforcement-search:-webkit-autofill {
  background-color: white !important;
  color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.enforcement-search::placeholder {
  color: #000;
}

.sort-icon {
  font-weight: 300;  
  font-size: 12px;  
  opacity: 0.7;  
  margin-left: 5px;  
  display: inline-block; 
}

.clear-filters-enforcement {
  background-color: #eb652b;
  color: white;
  border: none;
  padding: 8px 16px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 40px;
}

.clear-filters-enforcement:hover {
  background-color: #d15421;
}


@media (max-width: 768px) {
  .enforcement-table {
    width: 100%;
    font-size: 10px;
    font-weight: 300 !important;
  }

  .enforcement-table th:nth-child(1),
  .enforcement-table td:nth-child(1) {
    width: 20%;
  }

  .enforcement-table th:nth-child(2),
  .enforcement-table td:nth-child(2) {
    width: 36%;
  }

  .enforcement-table th:nth-child(3),
  .enforcement-table td:nth-child(3) {
    width: 20%;
  }

  .enforcement-table th:nth-child(4),
  .enforcement-table td:nth-child(4) {
    width: 24%;
  }

  .enforcement-table th,
  .enforcement-table td {
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
  }

  .enforcement-table th:nth-child(4),
  .enforcement-table td:nth-child(4) {
    text-align: left;
  }

  .enforcement-table th,
  .enforcement-table td {
    vertical-align: top;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .enforcement-table th:nth-child(1),
  .enforcement-table td:nth-child(1),
  .enforcement th:nth-child(2),
  .enforcement-table td:nth-child(2) {
    padding-right: 10px;
  }

  .enforcement-filters-row {
    flex-direction: column;
    gap: 10px;
  }
}

.enforcement-react-select {
  flex: 1;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  background-color: #fff;
}

.enforcement-react-select .react-select__control {
  width: 100%;
  min-width: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
}

.enforcement-react-select .react-select__control:hover {
  border-color: #eb652b;
}

.enforcement-react-select .react-select__control--is-focused {
  border-color: #eb652b !important;
  box-shadow: none !important; 
  outline: none !important; 
}

.enforcement-react-select .react-select__placeholder {
  color: #000;
}

.enforcement-react-select .react-select__single-value {
  color: #000;
}